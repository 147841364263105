.lock-screen {
    background-color: rgba(17,31,59, 0.9);
    width: 100vw;
    height: 100vh;
    position: relative;
}

.modal {
    margin: 0 auto;
    background-color: #FFFFFF;
    position: absolute;
    top: 40%;
    left: 35%;
    width: 30%;
    height: 10%;
    padding: 20px;
    border-radius: 4px;
}