/* nav's logo */
.logo-img {
    height: 21px;
    vertical-align: middle;
}

.logo-txt-padding {
    margin-top: -2px;
    margin-left: 0.6em;
}

.logo-padding {
    margin-top: 0.5em;
}

/* nav's links */
.nav-links {
    margin-left: auto;
    margin-top: 0.5em;
    font-size: 16px;
}

.nav-link {
    margin-left: 1em;
    margin-right: 1em;
}

/* phone */
@media only screen and (max-width: 480px) {
    .logo-txt-padding{
        margin-top: -6px;
    }

    .logo-padding {
        margin-top: 1.2em;
    }


    .nav-links {
        display: none;
    }

}