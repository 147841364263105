.work-hrs-container {
    width: 100%;   
}

.calc-work-hrs {
    display: flex;
    flex-direction: row;
}

ul.checkmark {
    list-style: none;
} 

ul.checkmark li.checkmark:before {
    content: '✓';
    margin-left: -3em;
    margin-right: 1.3em;
}

ul.checkmark li:before {
    content: 'o';
    margin-left: -3em;
    margin-right: 1.3em;
}

.d90m100 {
    width: 90%;
}

.whatever a.active {
    color: red;
}

/* phone */
@media only screen and (max-width: 480px) {

    .d90m100 {
        width: 100%;
    }

}