.cta {
    background-image: url('./images/bg.png');
    font-family: 'Sifonn Pro', sans-serif;
    font-size: 48px;
    line-height: 58px;
    color: #FF0081;
    padding-top: 215px;
    padding-bottom: 190px;
    padding-left: 0px;
    margin-left: 0px;
}