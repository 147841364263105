
.about-me-headline {
    font-size: 34px;
}

.add-in-desktop {
    background: linear-gradient(to bottom right, #FFFFFF 0%, #FFFFFF 50%, #111F3B 50%, #111F3B 100%);
    padding-bottom: 4em;
}

/* phone */
@media only screen and (max-width: 480px) {
    .add-padding-mobile {
        padding-top: 1em;
    }


    .add-in-desktop {
        padding-bottom: 0em;
        background: none;
    }
    
}