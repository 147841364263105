.hero {
    background-color: #111F3B;
    color: #FFFFFF;
    padding-top: 7em;
    padding-bottom: 5em;
}

.desktop-view {
    display: block;
}

.mobile-view {
    display: none;
}

.no-underline {
    text-decoration: none;
}

.section-separator {
    padding-top: 2.5em;
    padding-bottom: 1.25em;
    padding-left: 1em;
}

.cs-box {
    width: 100%;
    height: auto;
    min-height: 50%;
    display: inline-block;
    /* font */
    color: #FFFFFF;
    /* spacing */
    padding-bottom: 2.5em;
    margin-bottom: 5%;
    /* border */
    border-radius: 4px;
    /* background */
    background-image: -webkit-linear-gradient(150deg,#FFFFFF 39%, #111F3B 39%);
    /* cursor */
    cursor: pointer;
}

.cs-box-2 {
    width: 100%;
    height: auto;
    min-height: 50%;
    display: inline-block;
    /* font */
    color: #111F3B;    
    /* spacing */
    padding-bottom: 2.5em;
    margin-bottom: 5%;
    /* border */
    border-radius: 4px;
    /* background */
    background-image: -webkit-linear-gradient(150deg,#111F3B 39%, #FFFFFF 39%);
    /* cursor */
    cursor: pointer;
}

.cs-mobile-box {
    padding: 2.5em 2em 1.5em;
    margin-bottom: 1.5em;
    background: rgba(17, 31, 59, 1);
    color: #FFFFFF;
    border-radius: 12px;

}

.cs-mobile-content-box {
    /* spacing */
    padding-top: 2.5em;
    margin-bottom: 5%;
    /* cursor */
    cursor: pointer;
}

.cs-mobile-content-box:hover{
    text-transform: none;
}

.read-more-box {
    margin-top: 1.5em;
}

.read-more-button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 30px;
}

.hashtag {
    margin-right: 0.5em;
}

.cs-content {
    float: left;
    width: 50%;
    padding-top: 3em;
    padding-left: 2.5em;
}

.cs-img {
    float: right; 
    width: 50%;
}

.cs-img img {
    max-height: 100%;
    max-width: 100%;
    padding-top: 2em;
    padding-bottom: .5em;
}

/* animated divider */
.editorial {
    display: block;
    width: 100%;
    height: 60px;
    max-height: 60px;
    margin: 0;
    z-index:5;
    position:absolute;
    left:0px;
    float:left;
    margin-top: 65px;
  }
  
  .parallax1 > use {
    animation: move-forever1 10s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  .parallax2 > use {
    animation: move-forever2 8s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  .parallax3 > use {
    animation: move-forever3 6s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  .parallax4 > use {
    animation: move-forever4 4s linear infinite;
    &:nth-child(1) {
      animation-delay: -2s;
    }
  }
  @keyframes move-forever1 {
    0% {
      transform: translate(85px, 0%);
    }
    100% {
      transform: translate(-90px, 0%);
    }
  }
  @keyframes move-forever2 {
    0% {
      transform: translate(-90px, 0%);
    }
    100% {
      transform: translate(85px, 0%);
    }
  }
  @keyframes move-forever3 {
    0% {
      transform: translate(85px, 0%);
    }
    100% {
      transform: translate(-90px, 0%);
    }
  }
  @keyframes move-forever4 {
    0% {
      transform: translate(-90px, 0%);
    }
    100% {
      transform: translate(85px, 0%);
    }
  }
  

/* phone */
@media only screen and (max-width: 480px) {

    .hero {
        padding-top: 20%;
        padding-bottom: 15%;
    }

    .desktop-view {
        display: none;
    }

    .mobile-view {
        display: block;
    }

    .section-separator {
        padding-top: 1.5em;
        padding-bottom: 0.8em;
        padding-left: 0;
        text-align: center;
    }

    .cs-box {
        background: #111F3B;
    }

    .hashtag {
        margin-right: 0;
        padding-top: 0.5em;
        font-size: 16px;
    }

    .editorial {
        margin-top: 0.5em;
    }
}

/* tablet */
@media only screen and (min-width: 481px) and (max-width: 1024px) {
    
    .hero {
        padding-top: 15%;
        padding-bottom: 10%;
    }

}

/* small desktop */
@media only screen and (min-width: 1025px) and (max-width: 1279px) {
}

/* big desktop */
@media only screen and (min-height: 1280px) and (max-height: 9999px) {

}
