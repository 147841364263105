.icon-age-of-child {
    list-style-image: url('images/icon-age-of-child.png');
    padding-top: 15px;
}

.icon-flexible-work {
    list-style-image: url('images/icon-flexible-work.png');
    padding-top: 15px;
}

.icon-income-level {
    list-style-image: url('images/icon-income-level.png');
    padding-top: 15px;    
}