.footer {
    float: right;
    text-align: right;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.logo {
    float: right;
    margin-bottom: 5px;
}

.logo-img {
    margin-top: -15px;
}

.logo-txt {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 28px;
    color: #111F3B;
    padding-left: 20px;
}
