.link, .link:hover, .link:visited {
    color: #111F3B;
}

.link:hover {
    text-decoration: none;
    background-image: linear-gradient(120deg, #FF0081 0%, #FFFFFF 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.3em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}

.link-dark, .link-dark:hover, .link-dark:visited {
    color: #FFFFFF;
}

.link-dark:hover {
    text-decoration: none;
    background-image: linear-gradient(120deg, #FF0081 0%, #FFFFFF 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.3em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}


.logo-container {
    position: relative;     /* to align img */
    display: inline-block;  /* to hover img */
}

.logo-container .hover-img {
	display: none;
	z-index: 1;			
}

.logo-container:hover .hover-img {
	display: inline;
}

