@font-face {
    font-family: 'Sifonn Pro';
    font-style: normal;
    font-weight: normal;
    src: local('Sifonn Pro'), url('../fonts/SifonnPro.woff') format('woff');
}

@font-face {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: normal;
    src: local('Bebas Neue'), url('../fonts/BebasNeue.woff') format('woff');
}

.line {
    width: 27px;
    height: 3px;
    background-color: #FF0081;
    margin-top: 1em;
    margin-bottom: 1em;
}

hr {
    border-top: 1px solid #BFC3CB;
}

/* ============================================================================
 * Text
 * ============================================================================
 */

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    line-height: 1.58;
}

.body{
    font-family: 'Montserrat', sans-serif;
    font-size: 21px;
    line-height: 1.58;
}

.name {
    font-family: 'Sifonn Pro', serif;
    font-size: 63px;
    line-height: 1.25;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 0.1em;
}

.headline {
    font-family: 'Montserrat', sans-serif;
    font-size: 31px;
    line-height: 1.25;
}

.title {
    font-family: 'Sifonn Pro', serif;
    font-size: 42px;
    line-height: 1.25;
    padding-bottom: 0.1em;
}

.subtitle {
    font-family: 'Bebas Neue', serif;
    font-size: 28px;
    line-height: 1.22;
    letter-spacing: 0.1em;
    padding-bottom: 0.1em;
}

.project-title {
    font-family: 'Bebas Neue', serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 1.22;
    letter-spacing: 0.07em;
    padding-bottom: 2em;
}

.project-objective {
    font-family: 'Sifonn Pro', serif;
    font-size: 42px;
    line-height: 1.25;
    padding-bottom: 1.8em;
}

.project-overview {
    font-size: 18px;
    line-height: 2.1;
    padding-top: 0.8em;
}

.header {
    /*
    font-family: 'Bebas Neue', serif;
    font-size: 51px;
    line-height: 1.15;
    letter-spacing: 0.05em;
    font-weight: 600;
    padding-bottom: 0.6em;
    */  
    font-family: 'Sifonn Pro', serif;
    font-size: 39px; 
    line-height: 1.25;
}

.subheader {
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    line-height: 1.22;
    font-weight: 600;
    padding-top: 1.4em;
}


.preheader {
    font-family: 'Bebas Neue', serif;
    font-size: 28px;
    line-height: 2.4;
    letter-spacing: 0.07em;
    font-weight: 600;
}

.presubheader {
    font-family: 'Bebas Neue', serif;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.07em;
    font-weight: 600;
}

.caption {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
    padding-top: 1em;
}

.logo {
    font-family: 'Sifonn Pro', serif;
    font-size: '28px';
    color: #FFFFFF;
}

.caption-left {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.4;
    padding-top: 1em;
}

 /* ============================================================================
 * Image 
 * ============================================================================
 */

.shadow {
    box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
    -moz-box-shadow: 0 1px 2px rgba(34,25,25,0.4);
    -webkit-box-shadow: 0 1px 3px rgba(34, 25, 25, 0.4);
}

 /* ============================================================================
 * Background
 * ============================================================================
 */

.navy-bg {
    background-color: #111F3B;
    color: #FFFFFF;
}

.blue-bg {
    background-color: #04B6DB;
    color: #FFFFFF;
}

.light-blue-bg {
    background-color: #EDF3FF;
}

.pink-bg {
    background-color: #FF0081;
    color: #FFFFFF;
}

.gray-bg {
    background-color: #BFC3CB;
}

.white-bg {
    background-color: #FFFFFF;
}

.diag-bg-1{
    background-image: -webkit-linear-gradient(150deg, #FFFFFF 49%, #111F3B 49%);
}

.diag-bg-2{
    background-image: -webkit-linear-gradient(150deg, #111F3B 49%, #FFFFFF 49%);
}

.triangle-bg {
    background: linear-gradient(to bottom right, #FFFFFF 0%, #FFFFFF 50%, #111F3B 50%, #111F3B 100%);
}

.cta-bg {
    background-color: #233553;
}

 /* ============================================================================
 * Text Color
 * ============================================================================
 */

.navy-txt {
    color: #111F3B;
}

.pink-txt {
    color: #FF0081;
}

.yellow-txt{
    color: #F7C504;
}

.blue-txt {
    color: #04B6DB;
}

.purple-txt{
    color: #9C49FF;
}

.white-txt {
    color: #FFFFFF;
}

.gray-txt {
    color: #BFC3CB;
}

.rainbow-txt {
    background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* ============================================================================
 * Alignment
 * ============================================================================
 */

.center-txt {
    text-align: center;
}

.center-div {
    margin: 0 auto;
}

.center-img-hv {
    text-align: center;
    vertical-align: middle;
}

/*

.right-txt {
    text-align: right;
}

.center-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}


*/
 
/* vertical-align-parent and vertical-align-child are used together */ 
/*
.vertical-align-parent {
    padding: 5% 0;
}

.vertical-align-child {
    padding: 10% 0;
}

.vertical-align {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
*/

/* ============================================================================
 * Add spacing between number and text in list
 * ============================================================================
 */

ol {
    padding-left: 1em;
}
li {
    padding-left: 1em;
}


/* ============================================================================
 * Buttons
 * ============================================================================
 */

 /*
.case-study-button {
    width: 138px;
}

.cta-button {
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
}
*/

/* clear button --------------------------------------------------------- */

.clear-button {
    /* text */
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    /* background color */
    background: none;
    /* button */
    border: 2px #FFFFFF solid;
    border-radius: 50px;
    cursor: pointer;
    /* animation */
    transition: 0.5s;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.clear-button:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    margin: 8px 10px 12px;
    text-decoration: none;
    color: #FFFFFF;
}

/* gradient button --------------------------------------------------------- */

 .gradient-button {
    /* text */
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    padding: 6px 16px;
    /* background color */
    background-size: 200% auto;
    /* button */
    border-radius: 4px;
    cursor: pointer;
    /* animation */
    transition: 0.5s;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.gradient-button-circular {
    border-radius: 25px;
    padding: 15px 30px;
    max-width: 180px;
}

.gradient-button:hover{
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    margin: 8px 10px 12px;
    text-decoration: none;
    color: #FFFFFF;
}

.gradient-button-purple {
    background-image: linear-gradient(to right, #9C49FF 0%, #CB80FF 51%, #FF0081 100%)
}

.gradient-button-purple:hover { 
    background-position: right center; 
}

.gradient-button-pink {
    background-image: linear-gradient(to right, #FF0081 0%, #FC7CB9 51%, #04B6DB 100%)
}

.gradient-button-pink:hover { 
    background-position: right center; 
}

.gradient-button-blue {
    background-image: linear-gradient(to right, #04B6DB 0%, #74C5D6 51%, #04B6DB 100%)
}

.gradient-button-blue:hover { 
    background-position: right center; 
}

 /* ============================================================================
 * Custom Underline
 * ============================================================================
 */

.thick-underline {
   background-image: linear-gradient(120deg, #FF0081 0%, #FFFFFF 100%);
   background-repeat: no-repeat;
   background-size: 100% 0.3em;
   background-position: 0 88%;
   transition: background-size 0.25s ease-in;
}

 /* ============================================================================
 * Custom Scrolldown
 * ============================================================================
 */

.scroll-down {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 31px;
    height: 54px;
    color: #04B6DB;
    border-radius: 68px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 6vh;
    padding: 0;
    transition: height 0.25s ease;
}

.scroll-down .glyphicon-chevron-down {
    display: block;
    width: 100%;
    margin: 0 auto;
}

.scroll-down .glyphicon-chevron-down:nth-child(1) {
    animation: opacityChange1 2.5s infinite;
}

.scroll-down .glyphicon-chevron-down:nth-child(2) {
    animation: opacityChange2 2.5s infinite;
}

.scroll-down .glyphicon-chevron-down:nth-child(3) {
    animation: opacityChange3 2.5s infinite;
}

.scroll-down .chevron-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
}

@keyframes opacityChange1 {
    0%,100% {opacity: 0.2}
    33% {opacity: 1}
}

@keyframes opacityChange2 {
    0%,33%,100% {opacity: 0.2}
    66% {opacity: 1}
}

@keyframes opacityChange3 {
    0%,66% {opacity: 0.2}
    100% {opacity: 1}
}

 /* ============================================================================
 * Custom Quote 1
 * ============================================================================
 */

 blockquote {
	margin: 0 auto;
	padding: 1em;
	border-left: 5px solid #FF0081;
  }
  blockquote:before {
	display: none;
  }
  blockquote:not(:first-of-type) {
	margin-top: .5em;
  }
  blockquote p {
	color: #111F3B;
	font-size: 16px;
	line-height: 28px;
	font-style: italic;
  }
  blockquote footer {
	margin-top: .5em;
	padding: 0;
	color: #BFC3CB;
	font-size: 14px;
	text-align: left;
  }
  blockquote footer:before {
	content: '— ';
  }
  blockquote:nth-of-type(even) {
	text-align: right;
	border-left: none;
	border-right: 5px solid #FF0081;
  }
  blockquote:nth-of-type(even) footer {
	text-align: right;
  }
  blockquote:nth-of-type(even) footer:before {
	content: '';
  }
  blockquote:nth-of-type(even) footer:after {
	content: ' —';
  }
  @element 'blockquote' and (min-width: 300px) {
	blockquote {
	  padding: 1em 20% 1em 1em;
	}
	blockquote p {
	  font-size: 14pt;
	}
	blockquote:nth-of-type(even) {
	  padding: 1em 1em 1em 20%;
	}
  }

/* ============================================================================
 * Custom Quote 2
 * ============================================================================
 */



 /* ============================================================================
 * Custom Carousel
 * ============================================================================
 */

.carousel-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
.carousel-left-container {
    width: 10%;
    background: none;
    border: none;
    font-size: 25px;
    color: #BFC3CB;
}

.carousel-center-container {
    width: 80%;    
    background: none;
    border: none;
}

.carousel-right-container {
    width: 10%;    
    background: none;
    border: none;
    font-size: 25px;
    color: #BFC3CB;
}

.carousel-dot-group{
    text-align: center;
    margin-top: 20px;
}

.carousel-dot-group .carousel__dot {
    width: 25px;
    height: 4px;
    margin-left: 4px;
    background-color: #BFC3CB;
    border: none;
}

.carousel-dot-group .carousel__dot--selected {
    background-color: #9C49FF;
    border: none;
}

.carousel-center-container .carousel__slide {
    border: 2px #111F3B;
}

/* ============================================================================
 * Custom Cursor
 * ============================================================================
 */

 .zoom-in-cursor {
     cursor: zoom-in;
 }

 /* ============================================================================
 * Link
 * ============================================================================
 */

 /* sidenav */
.sidenav a {
    color: #111F3B;
}

.sidenav a:hover {
    text-decoration: none;
}

.icon {
    color: #111F3B;
}

.icon:hover {
    color: #9C49FF;
}

 /* nav */
.link, .link:hover, .link:visited, link:active {
    color: #111F3B;
    text-decoration: none;
}

.dark-link, .dark-link:hover, .dark-link:visited, .dark-link:active {
    color: #FFFFFF;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
    background-image: linear-gradient(120deg, #FF0081 0%, #FFFFFF 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.3em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
}

.dark-link:hover {
    text-decoration: none;
    background-image: linear-gradient(120deg, #FF0081 0%, #111F3B 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.3em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;

}

.bottom-nav {
    display: none;
}

/* ============================================================================
 * Img + Text Layout
 * ============================================================================
 */

.layout1-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}

.layout1-img {
    width: 34%;
}

.layout1-space {
    width: 5%;
}

.layout1-content {
    width: 62%;
}

.layout2-img {
    float: left;
    margin-right: 30px;
    margin-bottom: 10px;
}

.layout2-img img{
    width: 200px;
}

/* ============================================================================
 * Padding
 * ============================================================================
 */



.lr-1em {
    padding-left: 1em;
    padding-right: 1em;
}

.lr-2em {
    padding-left: 2em;
    padding-right: 2em;
}

.lr-3em {
    padding-left: 3em;
    padding-right: 3em;
}

.lr-4em {
    padding-left: 4em;
    padding-right: 4em;
}


.top-1em {
    padding-top: 1em;
}

.top-1p5em {
    padding-top: 1.5em;
}

.top-2em {
    padding-top: 2em;
}

.top-3em {
    padding-top: 3em;
}

.top-4em {
    padding-top: 4em;
}




.bottom-1em {
    padding-bottom: 1em;
}

.bottom-2em {
    padding-bottom: 2em;
}

.bottom-3em {
    padding-bottom: 3em;
}

.bottom-4em {
    padding-bottom: 4em;
}


/* ============================================================================
 * Responsive
 * ============================================================================
 */

 .no-show-desktop {
    display: none;
}

/* phone */
@media only screen and (max-width: 480px) {
    body {
        font-size: 18px;
    }

    .body {
        font-size: 18px;
    }

    .name {
        font-size: 51px;
    }

    .headline {
        font-size: 25px;
    }
    
    .title {
        font-size: 34px;
    }
    
    .subtitle {
        font-size: 24px;
    }

    .project-title {
        font-size: 23px;
    }

    .project-objective {
        font-size: 30px;
    }

    .project-overview {
        font-size: 16px;
    }

    .header {
        /*
        font-size: 30px;
        */
        font-size: 34px;
    }
    
    .subheader {
        font-size: 24px;
    }

    .preheader {
        font-size: 22px;
    }
    
    .presubheader {
        font-size: 16px;
    }

    .logo {
        font-size: 24px;
    }

    .no-show-mobile {
        display: none;
    }

    .no-show-desktop {
        display: block;
    }
    
    .bottom-nav {
        display: inline;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .top-4em {
        padding-top: 2em;
    }

    .bottom-4em {
        padding-bottom: 2em;
    }

    .padding-for-footer {
        padding-bottom: 2em;
    }

    .carousel-container {
        width: 90%;
        margin: 0 auto;
    }

}

/* tablet */
@media only screen and (min-width: 481px) and (max-width: 800px) {
    .gradient-button {
        padding: 4px 10.6px;
    }

    .name {
        font-size: 51px;
    }

    .headline {
        font-size: 25px;
    }
}